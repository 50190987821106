var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-app-bar',{class:{
      events: _vm.routePath.includes('events') || _vm.routePath.includes('profile'),
      register: _vm.routePath === '/events/register',
      landing: _vm.landingList.includes(_vm.routeName),
      store: _vm.routeName == 'Store',
    },attrs:{"id":"headerApp","color":"transparent","height":"140px","absolute":""}},[_c('v-row',{staticClass:"align",class:{
        limiter: _vm.landingList.includes(_vm.routeName) || !_vm.routePath.includes('events') || !_vm.routePath.includes('profile'),
      }},[_c('v-col',{staticClass:"space",style:(_vm.routePath.includes('events') || _vm.routePath.includes('profile') || _vm.landingList.includes(_vm.routeName)
            ? 'padding:0'
            : 'padding-inline: clamp(1em, 4vw, 4em)')},[_c('a',{staticClass:"center",attrs:{"href":"/#/"}},[(_vm.responsiveActions)?_c('img',{staticClass:"logoHeaderEvents",attrs:{"src":require("@/assets/logo/mintickt_beta_mobile.svg"),"alt":"logo"}}):_c('img',{staticClass:"logoHeader",attrs:{"src":require("@/assets/logo/mintickt_beta.svg"),"alt":"logo"}})]),_c('aside',{staticClass:"container-buttons-header center",staticStyle:{"gap":"20px"},style:(("\n            " + ((_vm.routePath === '/events' || !_vm.user) ? '' : 'display:contents') + ";\n            " + (_vm.routeName === 'Landing' ? 'flex-direction: row-reverse' : '') + "\n          "))},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:((_vm.routePath === '/events' || !_vm.user) && _vm.canShowBtn),expression:"(routePath === '/events' || !user) && canShowBtn"}],staticClass:"h9-em eliminarmobile",class:{
              createEventBtnLanding: _vm.landingList.includes(_vm.routeName),
              createEventBtn: _vm.routePath.includes('/events') || _vm.routePath.includes('/store')
            },on:{"click":_vm.goToEvent}},[_c('span',[_vm._v("create an event")])]),_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"menuButtonProfile"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{buttonProfile: _vm.user, login: !_vm.user},attrs:{"title":_vm.user},on:{"click":function($event){_vm.user ? _vm.menuOpened = !_vm.menuOpened : _vm.connectRamper()}}},'v-btn',_vm.user ? attrs : undefined,false),_vm.user ? on : undefined),[_c('div',{staticClass:"center"},[(_vm.user)?_c('img',{attrs:{"src":require("@/assets/logo/near.svg"),"alt":"near"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.user || "Log In"))])]),(_vm.user)?_c('v-icon',{style:(_vm.menuLogin ? 'transform: rotate(180deg)' : null),attrs:{"color":"#fff"}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)]}}]),model:{value:(_vm.menuLogin),callback:function ($$v) {_vm.menuLogin=$$v},expression:"menuLogin"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{attrs:{"to":'/profile'}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/icons/ticket.svg"),"alt":"profile"}}),_c('span',[_vm._v("My tickets")])])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$ramper.openWallet()}}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/icons/wallet.svg"),"alt":"wallet"}}),_c('span',[_vm._v("Open Wallet")])])],1),_c('v-list-item',{attrs:{"to":"/events"}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/icons/building.svg"),"alt":"profile"}}),_c('span',[_vm._v("Events")])])],1),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/icons/log-out.svg"),"alt":"logout"}}),_c('span',[_vm._v("Log out")])])],1)],1)],1),_c('modal-fill',{ref:"modalfill"})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }